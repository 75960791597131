import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@chakra-ui/react";

import kwf from "./Logos/Logo2.png";
import obf from "./Logos/Logo1.png";
import hef from "./Logos/Logo3.png";
import stl from "./Logos/Logo4.png";
import roh from "./Logos/Logo5.png";
import brh from "./Logos/Logo6.png";
import fin from "./Logos/Logo7.png";
import shz from "./Logos/Logo9.png";
import vgh from "./Logos/Logo10.png";
import mai from "./Logos/Logo11.png";
import fhf from "./Logos/Logo15.png";
import fbw from "./Logos/Logo16.png";
import ipx from "./Logos/Logo17.png";
import hgl from "./Logos/Logo18.png";
import bfr from "./Logos/Logo19.png";
import rht from "./Logos/Logo20.png";
import wfs from "./Logos/Logo21.png";
import psr from "./Logos/Logo22.png";
import srh from "./Logos/Logo23.png";
import fsf from "./Logos/Logo24.png";
import { BrowserView, MobileView, } from 'react-device-detect';


export default function LogoSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1, autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    CssEase: "linear",
  };

  return (
    <div>
      <Box m="80">
        <Box
          sx={{
            ".slick-dots": {
              transform: "translateY(1em)"
            },
            ".slick-dots li button": {
              _before: {
                transition: "0.2s",
                content: "''",
                borderRadius: "100%",
                background: "cyan.500"
              }
            },
            ".slick-arrow": {
              backgroundColor: "cyan.500",
              color: "white",
              w: "30px",
              h: "50px",
              transition: "0.2s",
              _hover: {
                backgroundColor: "cyan.500",
                color: "white"
              },
              _focus: {
                backgroundColor: "cyan.500",
                color: "white"
              },
              _before: {
                transition: "0.2s"
              }
            },
            ".slick-prev": {
              left: "-40px",
              _before: {
                content: '"◀"'
              }
            },
            ".slick-next": {
              right: "-40px",
              _before: {
                content: '"▶"'
              }
            }
          }}
        >


          <Slider {...settings}>
            <div>
              <h3><a href="https://www.fundraiso.com/de/organisations/furstlich-schwarzenbergsche-familienstiftung" target="_blank" rel="noopener noreferrer"><img src={fsf} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.facebook.com/p/Sattler-F-F-Holz-KG-100057409939717/" target="_blank" rel="noopener noreferrer"><img src={srh} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://forstservice-wuertenberger.de/" target="_blank" rel="noopener noreferrer"><img src={wfs} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://reichardt-online.de/" target="_blank" rel="noopener noreferrer"><img src={rht} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://baumpflege-buffler.de/" target="_blank" rel="noopener noreferrer"><img src={bfr} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="http://www.hagl-forst.de/" target="_blank" rel="noopener noreferrer"><img src={hgl} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://impex-forstmaschinen.de/de/" target="_blank" rel="noopener noreferrer"><img src={ipx} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.peterstraubinger.de/" target="_blank" rel="noopener noreferrer"><img src={psr} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.forstbw.de/startseite/" target="_blank" rel="noopener noreferrer"><img src={fbw} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.eichenfreund.de/" target="_blank" rel="noopener noreferrer"><img src={fhf} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.kommunalwald.de/" target="_blank" rel="noopener noreferrer"><img src={kwf} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.bundesforste.at/" target="_blank" rel="noopener noreferrer"><img src={obf} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3> <a href="https://www.hessen-forst.de/" target="_blank" rel="noopener noreferrer"><img src={hef} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.stiftung-liebenau.de/service-und-produkte/dienstleistungen/forstwirtschaft/" target="_blank" rel="noopener noreferrer"><img src={stl} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.rohde-landschaftspflege.de/" target="_blank" rel="noopener noreferrer"><img src={roh} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://holz-bross.de/" target="_blank" rel="noopener noreferrer"><img src={brh} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://holzhandel-fink.de/" target="_blank" rel="noopener noreferrer"><img src={fin} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3> <a href="https://forstbetrieb-schaetzle.de/startseite.html" target="_blank" rel="noopener noreferrer"><img src={shz} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://www.vieghofer.com/one-layout/" target="_blank" rel="noopener noreferrer"><img src={vgh} width="300" height="auto" /></a></h3>
            </div>
            <div>
              <h3><a href="https://branchenbuch.meinestadt.de/trossingen/company/979019" target="_blank" rel="noopener noreferrer"><img src={mai} width="300" height="auto" /></a></h3>
            </div>
          </Slider>


        </Box>
      </Box>
    </div>
  );
}