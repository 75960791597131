import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";

export default function LogoSliderMobile() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1, autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    CssEase: "linear",
    dots: true

  };

  return (
    <div>
      <center>
        <Box m="10">
          <Box
            sx={{
              ".slick-dots": {
                transform: "translateY(1em)"
              },
              ".slick-dots li button": {
                _before: {
                  transition: "0.2s",
                  content: "''",
                  borderRadius: "100%",
                  background: "cyan.500"
                }
              },
              ".slick-arrow": {
                backgroundColor: "cyan.500",
                color: "white",
                w: "30px",
                h: "50px",
                transition: "0.2s",
                _hover: {
                  backgroundColor: "cyan.500",
                  color: "white"
                },
                _focus: {
                  backgroundColor: "cyan.500",
                  color: "white"
                },
                _before: {
                  transition: "0.2s"
                }
              },
              ".slick-prev": {
                left: "-50%",
                _before: {
                  content: '"◀"'
                }
              },
              ".slick-next": {
                right: "-35%",
                _before: {
                  content: '"▶"'
                }
              }
            }}

            style={{ overflow: "hidden" }}
          >

            <Slider {...settings}>
              <div>
                <h3><a href="https://www.fundraiso.com/de/organisations/furstlich-schwarzenbergsche-familienstiftung" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo24.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.facebook.com/p/Sattler-F-F-Holz-KG-100057409939717/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo23.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://forstservice-wuertenberger.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo21.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://reichardt-online.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo20.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://baumpflege-buffler.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo19.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="http://www.hagl-forst.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo18.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://impex-forstmaschinen.de/de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo17.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.peterstraubinger.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo22.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.forstbw.de/startseite/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo16.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.eichenfreund.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo15.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.kommunalwald.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo2.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.bundesforste.at/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo1.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3> <a href="https://www.hessen-forst.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo3.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.stiftung-liebenau.de/service-und-produkte/dienstleistungen/forstwirtschaft/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo4.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.rohde-landschaftspflege.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo5.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://holz-bross.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo6.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://holzhandel-fink.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo7.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3> <a href="https://forstbetrieb-schaetzle.de/startseite.html" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo9.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://www.vieghofer.com/one-layout/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo10.png")} width="180" height="auto" /></a></h3>
              </div>
              <div>
                <h3><a href="https://branchenbuch.meinestadt.de/trossingen/company/979019" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo11.png")} width="180" height="auto" /></a></h3>
              </div>
            </Slider>
          </Box>
        </Box>
      </center>
    </div>
  );
}