import React from "react";
import { Navbar } from "./Navbar";
import Page from "./Page";
import Footer1 from "./Footer";
import "./style.css";
import Datenschutz from "./Datenschutz";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import ScrollToTop from "./Router-Scripts/ScrollToTop"
import Impressum from "./Impressum";
import Kontakt from "./Kontakt";
import PageScrollProgressBar from "react-page-scroll-progress-bar";
import { Scrollbars } from 'react-custom-scrollbars-2';
import About from "./AboutUs";
import { BrowserView, MobileView, TabletView, } from 'react-device-detect';
import AGB from "./AGB";
import Forstunternehmer from "./Forstunternehmer";
import CookieConsent, { Cookies } from "react-cookie-consent";



function App() {
  return (
    <div>

      <MobileView>
        <PageScrollProgressBar color="#009900" bgColor="transparent" height="10.5rem" />
      </MobileView>

      <BrowserView>
        <PageScrollProgressBar color="#009900" bgColor="transparent" height="7.3rem" />
      </BrowserView>

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/about" element={<About />} />
          <Route path="/agb" element={<AGB />} />
          <Route path="/moduluebersicht" element={<Forstunternehmer />} />
        </Routes>
        <Footer1 />
      </Router>

      <Scrollbars />
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B2A2A" }}
        buttonStyle={{ color: "#ffffff", fontSize: "13px", background: "#009900", borderRadius: "100px"}}
        expires={150}
      >
        Wir verwenden Cookies, um Ihre Browsing-Erfahrung zu verbessern. Indem Sie auf „Akzeptieren“ klicken, willigen Sie in unsere Verwendung von Cookies ein, wie in unserer <a href="/Datenschutz" style={{color:"#009900"}}>Datenschutzrichtlinie</a> beschrieben.
        
      </CookieConsent>
    </div>
  )
}

export default App;
