import { Scene1 } from "../imageSequences/Image-Sequences";
import { useImage } from "../hooks";

const AirpodsArray = () => {
  const [AA0, AA0S] = useImage(Scene1[0]);
  const [AA1, AA1S] = useImage(Scene1[1]);
  const [AA2, AA2S] = useImage(Scene1[2]);
  const [AA3, AA3S] = useImage(Scene1[3]);
  const [AA4, AA4S] = useImage(Scene1[4]);
  const [AA5, AA5S] = useImage(Scene1[5]);
  const [AA6, AA6S] = useImage(Scene1[6]);
  const [AA7, AA7S] = useImage(Scene1[7]);
  const [AA8, AA8S] = useImage(Scene1[8]);
  const [AA9, AA9S] = useImage(Scene1[9]);

  const [AA10, AA10S] = useImage(Scene1[10]);
  const [AA11, AA11S] = useImage(Scene1[11]);
  const [AA12, AA12S] = useImage(Scene1[12]);
  const [AA13, AA13S] = useImage(Scene1[13]);
  const [AA14, AA14S] = useImage(Scene1[14]);
  const [AA15, AA15S] = useImage(Scene1[15]);
  const [AA16, AA16S] = useImage(Scene1[16]);
  const [AA17, AA17S] = useImage(Scene1[17]);
  const [AA18, AA18S] = useImage(Scene1[18]);
  const [AA19, AA19S] = useImage(Scene1[19]);

  const [AA20, AA20S] = useImage(Scene1[20]);
  const [AA21, AA21S] = useImage(Scene1[21]);
  const [AA22, AA22S] = useImage(Scene1[22]);
  const [AA23, AA23S] = useImage(Scene1[23]);
  const [AA24, AA24S] = useImage(Scene1[24]);
  const [AA25, AA25S] = useImage(Scene1[25]);
  const [AA26, AA26S] = useImage(Scene1[26]);
  const [AA27, AA27S] = useImage(Scene1[27]);
  const [AA28, AA28S] = useImage(Scene1[28]);
  const [AA29, AA29S] = useImage(Scene1[29]);

  const [AA30, AA30S] = useImage(Scene1[30]);
  const [AA31, AA31S] = useImage(Scene1[31]);
  const [AA32, AA32S] = useImage(Scene1[32]);
  const [AA33, AA33S] = useImage(Scene1[33]);
  const [AA34, AA34S] = useImage(Scene1[34]);
  const [AA35, AA35S] = useImage(Scene1[35]);
  const [AA36, AA36S] = useImage(Scene1[36]);
  const [AA37, AA37S] = useImage(Scene1[37]);
  const [AA38, AA38S] = useImage(Scene1[38]);
  const [AA39, AA39S] = useImage(Scene1[39]);

  const [AA40, AA40S] = useImage(Scene1[40]);
  const [AA41, AA41S] = useImage(Scene1[41]);
  const [AA42, AA42S] = useImage(Scene1[42]);
  const [AA43, AA43S] = useImage(Scene1[43]);
  const [AA44, AA44S] = useImage(Scene1[44]);
  const [AA45, AA45S] = useImage(Scene1[45]);
  const [AA46, AA46S] = useImage(Scene1[46]);
  const [AA47, AA47S] = useImage(Scene1[47]);
  const [AA48, AA48S] = useImage(Scene1[48]);
  const [AA49, AA49S] = useImage(Scene1[49]);

  const [AA50, AA50S] = useImage(Scene1[50]);
  const [AA51, AA51S] = useImage(Scene1[51]);
  const [AA52, AA52S] = useImage(Scene1[52]);
  const [AA53, AA53S] = useImage(Scene1[53]);
  const [AA54, AA54S] = useImage(Scene1[54]);
  const [AA55, AA55S] = useImage(Scene1[55]);
  const [AA56, AA56S] = useImage(Scene1[56]);
  const [AA57, AA57S] = useImage(Scene1[57]);
  const [AA58, AA58S] = useImage(Scene1[58]);
  const [AA59, AA59S] = useImage(Scene1[59]);
  const [AA60, AA60S] = useImage(Scene1[60]);
 

  const newImages = Array.of(
    [AA0, AA0S],
    [AA1, AA1S],
    [AA2, AA2S],
    [AA3, AA3S],
    [AA4, AA4S],
    [AA5, AA5S],
    [AA6, AA6S],
    [AA7, AA7S],
    [AA8, AA8S],
    [AA9, AA9S],
    [AA10, AA10S],
    [AA11, AA11S],
    [AA12, AA12S],
    [AA13, AA13S],
    [AA14, AA14S],
    [AA15, AA15S],
    [AA16, AA16S],
    [AA17, AA17S],
    [AA18, AA18S],
    [AA19, AA19S],
    [AA20, AA20S],
    [AA21, AA21S],
    [AA22, AA22S],
    [AA23, AA23S],
    [AA24, AA24S],
    [AA25, AA25S],
    [AA26, AA26S],
    [AA27, AA27S],
    [AA28, AA28S],
    [AA29, AA29S],

    [AA30, AA30S],
    [AA31, AA31S],
    [AA32, AA32S],
    [AA33, AA33S],
    [AA34, AA34S],
    [AA35, AA35S],
    [AA36, AA36S],
    [AA37, AA37S],
    [AA38, AA38S],
    [AA39, AA39S],

    [AA40, AA40S],
    [AA41, AA41S],
    [AA42, AA42S],
    [AA43, AA43S],
    [AA44, AA44S],
    [AA45, AA45S],
    [AA46, AA46S],
    [AA47, AA47S],
    [AA48, AA48S],
    [AA49, AA49S],

    [AA50, AA50S],
    [AA51, AA51S],
    [AA52, AA52S],
    [AA53, AA53S],
    [AA54, AA54S],
    [AA55, AA55S],
    [AA56, AA56S],
    [AA57, AA57S],
    [AA58, AA58S],
    [AA59, AA59S],
    [AA60, AA60S]

    );
  return newImages;
};

export default AirpodsArray;
