import React, { useRef, useState } from "react";
import "./contact.css"
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Router-Scripts/ScrollToTopNoDelay';



const Kontakt = () => {
  const form = useRef();
  const [captchaValid, setCaptchaValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();


    if (captchaValid) {
      emailjs
        .sendForm(
          "service_r39u6cx",
          "template_8x7s738",
          form.current,
          "q7oJuPSFMMINo9FS6"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    } else {
      setShowError(true);
    }
  };


  function errorMessage() {
    if (showError === true) {
      return (

        <p>Test</p>
      )

    } else {
      return (<div></div>)
    }
  }


  function onChange(value) {
    setCaptchaValid(true);
    console.log("Captcha value:", value);
  }

  return (
    <div>
      <ScrollToTopNoDelay />
      <br></br>
      <MobileView>
        <h1>KONTAKT</h1>
      </MobileView>
      <BrowserView>
        <h1 style={{ marginRight: "8%", marginLeft: "10%",  fontSize: "50px" }}>KONTAKT</h1>
      </BrowserView>
 
      <MobileView>
        <div className="Datenschutz" style={{ marginRight: "12%" }}>
          <br></br>

          <section id="contact">
            <center>

              {/*End of Contact Option */}
              <form ref={form} onSubmit={sendEmail}>

                <label style={{ textAlign: "left", }}>Vorname*</label>
                <input type="text" name="vname" placeholder="Vorname" required />

                <label style={{ textAlign: "left", }}>Nachname*</label>
                <input type="text" name="name" placeholder="Nachname" required />

                <label style={{ textAlign: "left", }}>Email Adresse*</label>
                <input type="email" name="email" placeholder="Email Adresse" required />

                <label style={{ textAlign: "left", }}>Telefonnummer</label>
                <input type="number" name="tel" placeholder="Telefonnummer" />

                <label style={{ textAlign: "left", }}>Firma</label>
                <input type="text" name="company" placeholder="Firma" />

                <label style={{ textAlign: "left", }}>Ihre Nachricht*</label>
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Message"
                  required
                ></textarea>

                <ReCAPTCHA style={{ display: "flex", alignItems: "left" }} sitekey="6LeXvXkkAAAAAEGLVuv2uZfhYG6dc5OI3HjrQ4MB" onChange={onChange} required />
                {errorMessage()}

                <input
                  type="submit"
                  value="Absenden"
                  className="btn btn-primary"
                />
              </form>
              <br></br>
              <h2 style={{ textAlign: "left", fontSize: "15px", fontWeight: "500" }}>* - Pflichtfeld</h2>
            </center>
          </section>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </MobileView>
      <BrowserView>
        <div className="Datenschutz" style={{ marginRight: "5%" }}>
          <br></br>

          <section id="contact">
            <center>

              {/*End of Contact Option */}
              <form ref={form} onSubmit={sendEmail}>

                <label style={{ textAlign: "left", }}>Vorname*</label>
                <input type="text" name="vname" placeholder="Vorname" required />

                <label style={{ textAlign: "left", }}>Nachname*</label>
                <input type="text" name="name" placeholder="Nachname" required />

                <label style={{ textAlign: "left", }}>Email Adresse*</label>
                <input type="email" name="email" placeholder="Email Adresse" required />

                <label style={{ textAlign: "left", }}>Telefonnummer</label>
                <input type="number" name="tel" placeholder="Telefonnummer" />

                <label style={{ textAlign: "left", }}>Firma</label>
                <input type="text" name="company" placeholder="Firma" />

                <label style={{ textAlign: "left", }}>Ihre Nachricht*</label>
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Message"
                  required
                ></textarea>

                <ReCAPTCHA style={{ display: "flex", alignItems: "left" }} sitekey="6LeXvXkkAAAAAEGLVuv2uZfhYG6dc5OI3HjrQ4MB" onChange={onChange} required />
                {errorMessage()}

                <input
                  type="submit"
                  value="Absenden"
                  className="btn btn-primary"
                />
              </form>
              <br></br>
              <h2 style={{ textAlign: "left", fontSize: "15px", fontWeight: "500" }}>* - Pflichtfeld</h2>
            </center>
          </section>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </BrowserView>
    </div>



  );
};
export default Kontakt;