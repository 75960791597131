import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import LogoSliderMobile from './SliderMobile';
import ScrollToTop from './Router-Scripts/ScrollToTop';

const FadeInWhenVisible = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1.5 }}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, y: 35 }
      }}
    >
      {children}
    </motion.div>
  );
};

// Usage Example
const MobileHome = () => {
  return (
    <div>
      <ScrollToTop />
      <FadeInWhenVisible>
        <center><h2>DAS IST WOOD.IN.VISION</h2></center>
      </FadeInWhenVisible>

      <FadeInWhenVisible>
        <img src={require("./images/Mobile/Scene1.png")} style={{ width: "100%", height: "auto" }}></img>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR DIGITALISIEREN DEN WALD</h2>
          <p>
            WOOD.IN.VISION ermöglicht eine dynamische Betriebssteuerung
            für die nächste Generation der Forstwirtschaft.
            Wir denken in digital gestützten Prozessen, entwickeln
            und optimieren umfassende Anwendungen mit und für unsere Kunden.
            <br></br><br></br>
            Soft- und Hardware entwickelt von und für Forstprofis.
            <br></br><br></br>
            <b>Erfahren. Nachhaltig. Effizient. </b>
          </p>
          <br />
          <Link to='/kontakt' class="Contact">Jetzt Anfragen</Link>
          <br />
          <br />
          <Link to='https://www.magazin-allgaeu.de/arbeitenimallgaeu/woodinvision/' target="_blank" class="Contact">Artikel</Link>
        </div>
      </FadeInWhenVisible>



      {/*'<br /><br /><br />    <br /><br /><br />    <br /><br /> */}<br /><br /><br /><br />
      <FadeInWhenVisible>
        <img src={require("./images/Mobile/Scene2.png")} style={{ width: "100%", height: "auto" }}></img>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR VERBINDEN ALLE TEILNEHMER</h2>
          <p>
            WOOD.IN.VISION ist eine digitale Plattform,
            die cloud-basiert betrieben wird. Der
            Zugriff erfolgt lokal über den Web-Browser
            und mobil mit der W.I.V-App.
          </p>
        </div>
      </FadeInWhenVisible>




      {/*<br /><br /><br />    <br /><br /><br />    <br /><br />*/}<br /><br /><br /><br />
      <FadeInWhenVisible>
        <img src={require("./images/Mobile/Scene3.png")} style={{ width: "100%", height: "auto" }}></img>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR VERBINDEN FORSTMASCHINEN</h2>
          <p>
            WOOD.IN.VISION ist herstellerunabhängig und
            die Lösung für unterschiedliche
            Maschinenflotten. Ob auf dem Maschinen-PC
            oder mobil über Smartphone und Tablet.
          </p>
        </div>
      </FadeInWhenVisible>



      {/*<br /><br /><br />    <br /><br /><br />    <br /><br /> */}<br /><br /><br /><br />
      <FadeInWhenVisible>
        <img src={require("./images/Mobile/Scene4.png")} style={{ width: "100%", height: "auto" }}></img>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR DIGITALISIEREN DIE FEINERSCHLIEßUNG </h2>
          <p>
            WOOD.IN.VSION ist die Lösung für die Planung,
            Anlage, Aufzeichnung und Dokumentation von
            digitalen Rückegassen.
          </p>
          <br />
          <Link to='https://www.lrakn.de/service-und-verwaltung/aemter/kreisforstamt/digitale+fortschritte+bei+der+holzernte+-+wood+in+vision' target="_blank" class="Contact">Artikel</Link>
        </div>
      </FadeInWhenVisible>



      {/*<br /><br /><br />    <br /><br /><br />    <br /><br /> */}<br /><br /><br /><br />
      <FadeInWhenVisible>
        <img src={require("./images/Mobile/Scene5.png")} style={{ width: "100%", height: "auto" }}></img>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR HABEN ALLES IM BLICK</h2>
          <p>
            WOOD.IN.VISION fügt alle Einträge auf Dashboards
            die unterschiedliche Tätigkeiten im Forst planen,
            umsetzen, dokumentieren und abrechnen wollen.
          </p>

        </div>
      </FadeInWhenVisible>





      {/*<br /><br /><br />    <br /><br /><br />    <br /><br />*/}<br /><br /><br /><br />
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>WIR DENKEN LANGFRISTIG UND KUNDENNAH</h2>
          <p>
            Unser Antrieb ist es mit WOOD.IN.VISION eine
            zukunftsfähige Plattform mit digitalen Anwendungen
            bereit zu stellen, die eine ressourcenschonende,
            prozessorientierte, langfristig dokumentierbare und auf
            den Kreislaufgedanken basierte Waldbewirtschaftung
            ermöglichen. Wir handeln aus Überzeugung im Sinne einer
            ökologisch und ökonomisch nachhaltigen Forstwirtschaft.
          </p>
        </div>
      </FadeInWhenVisible>
      <br /><br /><br /><br />
      <FadeInWhenVisible>
        <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <h2 style={{ fontSize: "18px" }}>FORSTWIRTSCHAFT DER NÄCHSTEN GENERATION</h2>
          <p>
            WOOD.IN.VISION ist mobil on- und offline nutzbar. Alle Daten
            sind auch unter schwierigen Bedingungen, dank unserer Hardware
            georeferenziert. Die Cloud-Technologie ermöglicht es uns, alle
            Teilnehmer in der Forstwirtschaft digital und sicher miteinander
            zu vernetzen. Wir schaffen damit eine auf Transparenz und nahezu
            Echtzeit basierende Zusammenarbeit. Jeder Teilnehmer entscheidet
            dabei selbst, welche Daten zur Verfügung gestellt werden.
          </p>
        </div>
      </FadeInWhenVisible>


      <br /><br /><br /><br />

      <FadeInWhenVisible>
        <div>
          <center>
            <h2 >UNSERE KUNDEN</h2>
          </center>
          <LogoSliderMobile />
        </div>
      </FadeInWhenVisible>


      <br /><br /><br /><br />
      <FadeInWhenVisible>
        <div>
          <center>
            <h2 >UNSERE PARTNER</h2>
          </center>
          <center>
            <table style={{ display: "flex", justifyContent: "center", paddingLeft: "50%", paddingRight: "50%" }}>
              <tr>
                <td>
                  <a href="https://wahlersforsttechnik.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo14.png")} width="220" height="auto" style={{ paddingTop: "5px" }} /></a>
                  <a href="https://www.neuson-forest.com/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo13.png")} width="220" height="auto" style={{ paddingTop: "5px" }} /></a>
                  <a href="https://www.netwakevision.com/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo12.png")} width="220" height="auto" style={{ paddingTop: "5px" }} /></a>
                  <a href="https://www.hs-rottenburg.net/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo8.png")} width="220" height="auto" style={{ paddingTop: "5px" }} /></a>
                </td>

              </tr>
            </table>
          </center>
        </div>

      </FadeInWhenVisible>



      <br /><br /><br /><br />
      <FadeInWhenVisible>
        <div>
          <center>
            <h2>UNSERE PAKETE</h2>
          </center>


          <div className='Pakete'>
            <ScrollToTop />
            <table style={{ borderSpacing: "30px", width: "100%", marginTop: "-5%" }}>
              <tr>
                <td style={{ width: "calc(100%/3)", marginLeft: "30%", backgroundColor: "" }}>
                  <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                    <h1 style={{ textAlign: "center" }}>Cloud Basic</h1>
                    <h2 style={{ fontSize: "18px", fontWeight: "300", textAlign: "center", marginTop: "-3%" }}>für Unternehmer</h2>

                    <img class="" src={require("./images/Angebot.png")} alt="" width="150" height="130" />
                    <br></br>
                    <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                    <ul style={{ textAlign: "left" }}>
                      <li style={{ listStyleType: "none" }}>
                        <ul>
                          <li style={{ listStyleType: "none" }}>
                          </li>
                        </ul>


                      </li>
                      <ScrollToTop />

                    </ul>
                  </div>
                </td>
              </tr>

              <tr>



                <td style={{ width: "calc(100%/3)", marginRight: "30%", backgroundColor: "" }}>

                  <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                    <h1 style={{ textAlign: "center" }}>Cloud Advanced</h1>
                    <h2 style={{ fontSize: "18px", fontWeight: "300", textAlign: "center", marginTop: "-3%" }}>für Forstservices</h2>


                    <img class="" src={require("./images/Angebot.png")} alt="" width="150" height="130" />
                    <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                    <ul style={{ textAlign: "left" }}>
                      <li style={{ listStyleType: "none" }}>
                        <ul>
                          <li style={{ listStyleType: "none" }}>

                          </li>
                        </ul>


                      </li>
                    </ul>

                  </div>

                </td>

              </tr>

              <tr>


                <td style={{ width: "calc(100%/3)", backgroundColor: "" }}>
                  <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                    <h1 style={{ textAlign: "center" }}>Cloud Professional</h1>
                    <h2 style={{ fontSize: "18px", fontWeight: "300", textAlign: "center", marginTop: "-3%" }}>für Waldbesitzer</h2>
                    <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>



                      <img class="" src={require("./images/Angebot.png")} alt="" width="150" height="130" />
                      <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                      <ul style={{ textAlign: "left" }}>
                        <li style={{ listStyleType: "none" }}>
                          <ul>
                            <li style={{ listStyleType: "none" }}>

                            </li>
                          </ul>
                          <br></br>


                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>

            </table>
          </div>

        </div>
      </FadeInWhenVisible>



      <br />
      <FadeInWhenVisible>
        <div>
          <center>
            <h2 >MEHR ERFAHREN</h2>
          </center>

          <center> <iframe style={{ width: "95%", height: "25vh" }} width="1920" height="1080" src="https://www.youtube.com/embed/RRV6iKB8W7s" title="Das ist Wood.in.Vision | Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
          <br></br>

          <center><Link to='https://drive.google.com/uc?export=download&id=1WIkwMxzKIbvqxmZivlVSaoBZuxe3C2C1' class="Contact">W.I.V. Broschüre Herunterladen</Link></center>
          <br></br>
          <center><Link to='https://drive.google.com/uc?export=download&id=1OuV9BLq_lVnLnlwA0i0F3A6fRi4HID12' class="Contact">W.I.V. Starter Herunterladen</Link></center>
          <br></br>

        </div>
      </FadeInWhenVisible>



    </div>
  );
};

export default MobileHome;
