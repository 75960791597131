import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function ThreeFour() {

  return (
    <div>
      <div className="container">


      </div>
      <div style={{ display: "flex", margin: "auto" }}>
        <BrowserView>
          <table>
            <tr>
              <td><img src={require("./Images/3-4.png")} style={{ height: "450px" }}></img></td>
              <h1 className="headline" style={{ paddingLeft: "25px", display: "flex" }}>  Baumkataster</h1>
              <td style={{ paddingLeft: "25px", display: "flex" }}>
                Mit dem Baumkataster wird die die Erfassung von Grunddaten aller Bäume ermöglicht, die direkt einem Kunden oder Projekt zugeordnet werden können.


              </td>
              <tr>
                <td>
                  <ul style={{ float: "left" }}>
                    <li>
                      Erfassung der Grunddaten
                    </li>
                    <li>
                      Baumkontrolldaten
                    </li>
                    <li>
                      Direktes Anlegen von erforderlichen Maßnahmen und deren Abwicklung
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
          <br></br><br></br><br></br><br></br><br></br><br></br>
        </BrowserView>
        <MobileView>
          <table>
            <tr>
              <td><img src={require("./Images/3-4.png")} style={{ height: "auto", width: "30%" }} ></img></td>
            </tr>
            <tr>
              <td style={{ display: "flex", justifyContent: "left", width: "90%" }}>
                Mit dem Baumkataster wird die die Erfassung von Grunddaten aller Bäume ermöglicht, die direkt einem Kunden oder Projekt zugeordnet werden können.

                <br />

              </td>
              <tr>
                <td style={{ display: "flex", justifyContent: "center", width: "70%" }}>
                  <ul>
                    <li>
                      Erfassung der Grunddaten
                    </li>
                    <li>
                      Baumkontrolldaten
                    </li>
                    <li>
                      Direktes Anlegen von erforderlichen Maßnahmen und deren Abwicklung
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
        </MobileView>
      </div>
    </div>
  );
}

